import Carousel from 'react-bootstrap/Carousel'
import n1placeholder from "../../assets/NovPlaceHolder1.png"
import n2placeholder from "../../assets/novSec1.png"
import Image from 'react-bootstrap/Image'

import axios from 'axios'
import { AXIOS_HOST } from "../../config/axios.config"
import { useState, useEffect } from 'react'

export default function Novedades(){

    const [novedades, setNovedades] = useState([])

    const loadRecentNews = async () => {
        await axios.get(`${AXIOS_HOST}/noticias/recent`).then((res) => {
            setNovedades(res.data)
        })
    }

    useEffect(() => {
        loadRecentNews()
    }, [])

    return (
        <Carousel  style={{maxWidth:"1000px", marginLeft:"auto", marginRight:"auto"}}>

            {novedades.map((nov) => {
                //console.log(nov.archivos[0].nombreFile)
                return (
                    <Carousel.Item interval={5000}>
                        <a href={`/noticias/${nov.idNovedad}`}>
                            <img
                                crossOrigin='anonymous'
                                className='d-block w-100'
                                src={`${AXIOS_HOST}/filesNoticias/${nov.archivos[0].nombreFile}`}
                                alt={nov.titulo}
                                style={{maxHeight:"500px", aspectRatio:"2/1",}}
                            />

                            {nov.activeCaption ?
                            <Carousel.Caption style={{backgroundColor:"rgb(36, 36, 36, 0.8)"}}>

                                <h3>{nov.titulo}</h3>
                                <p style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", marginLeft:"2rem", marginRight:"2rem"}}>{nov.texto}</p>
                            </Carousel.Caption> : null
                            }
                        </a>
                    </Carousel.Item>
                )
            })
            }

        
        </Carousel>
    )
}