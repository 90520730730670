import TestHeader from "../Components/Header/headerV2";
import { ScheduleView, DaySchedule, colors, createTheme, ThemeContext } from "react-schedule-view"

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AXIOS_HOST } from "../config/axios.config";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { Popover, Button } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';



import ReglamentoPiscina from "../assets/PDF/REGLAMENTO INTERNO DE PISCINA.pdf"

export default function Horarios() {

  useEffect(() => {
    loadHorarios()
  }, [])

  const [modalEvent, setModalEvent] = useState({ show:false ,horaInicio: "", horaFin: "", dia: "", clase: "", status: 0  })

  const [horarios, setHorarios] = useState([])
  const [horarioActivo, setHorarioActivo] = useState([])
  const [tipos, setTipos] = useState([])
  const [tipoActivo, setTipoActivo] = useState(0)

  const [tClases, setTClases] = useState([])

  const loadHorarios = async () => {
    const tipos = await axios.get(`${AXIOS_HOST}/clases/alltipos`)
    const response = await axios.get(`${AXIOS_HOST}/horarios/all`)
    const TClases = await axios.get(`${AXIOS_HOST}/clases/all`)

    //console.log(tipos.data)
    //console.log(response.data.horarios)
    //console.table("TClases", TClases.data.clases)

    setHorarios(response.data.horarios)
    setTipos(tipos.data)
    setTClases(TClases.data.clases)

  }


  const data1 = [
    {
      name: "Lunes",
      events: [],
    }, {
      name: "Martes",
      events: [],
    }, {
      name: "Miercoles",
      events: [],
    }, {
      name: "Jueves",
      events: [],
    }, {
      name: "Viernes",
      events: [],
    }, {
      name: "Sabado",
      events: [],
    },
    {
      name: "Domingo",
      events: [],
    },
  ];

  const data = [
    {
      name: "Lunes",
      events: [],
    }, {
      name: "Martes",
      events: [],
    }, {
      name: "Miercoles",
      events: [],
    }, {
      name: "Jueves",
      events: [],
    }, {
      name: "Viernes",
      events: [],
    }, {
      name: "Sabado",
      events: [],
    },
    {
      name: "Domingo",
      events: [],
    },
  ];

  function loadHorariosData() {
    data.forEach((dia, index) => {
      //console.log(dia.name)

      const horariosDia = horarios.filter(horario => horario.dia === dia.name)
      //console.log(horariosDia)

      data[index].events = horariosDia.map(horario => {
        //separar el string de horario.hora por cada ":"
        const horaInicio = horario.hora.split(":")
        //const horaFin = horario.horaFin.split(":")
        //convertir el string a numero
        horaInicio[0] = Number(horaInicio[0])
        horaInicio[1] = Number(horaInicio[1])
        //console.log(horaInicio)
        //horaFin[0] = Number(horaFin[0])
        //horaFin[1] = Number(horaFin[1])
        return {
          startTime: horaInicio[0] + horaInicio[1] / 60,
          endTime: horaInicio[0] + horaInicio[1] / 60 + 1,
          title: horario.clase,
          //description: horario.descripcion,
          color: "royalblue"
        }
      })
    })
  }

  const theme = useContext(ThemeContext);
  //console.log(theme)


  const customTheme = createTheme(
    "apple", 
    {
    //minorGridlinesPerHour: 4,
    hourHeight: "55px",
    
    
  })

  function customEvent(data) {
    //console.log(data)

    
    //pasar tiempos a string

    


    //const horaInicio = startTimeArray[0] + ":" + Number(startTimeArray[1]) * 60 / 100
    //const horaFin = endTimeArray[0] + ":" + endTimeArray[1] * 60 / 100

    const { clase, dia, hora, horaFin, status } = data.data

    const horaInicio = hora.split(":")
    const horaF = horaFin.split(":")
    
    

    setModalEvent({show: true, horaInicio: horaInicio[0] + ":" + horaInicio[1], horaFin: horaF[0] + ":" + horaF[1], dia: dia, clase: clase, status: status})

  }




  return (
    <>
      <div className="MyBackground">
        <TestHeader />

        <div className="container" style={{ backgroundColor: "#fdfdfd", minHeight: "100vh", paddingTop: "3vh", paddingLeft: "3rem", paddingBottom: "2rem", textAlign: "left" }}>
          <span style={{ textAlign: "left", padding: "10px" }}>
            <h1>Horarios</h1>
          </span>

          {/** Selector de tipos */}
          <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" onSelect={(key) => setTipoActivo(key)}>
            {tipos.map((tipo, index) => {
              //obtener unicamente los horarios que corresponden al tipo de clase seleccionado
              const TClaseTipo = tClases.filter(tClase => tClase.idTipo === tipo.id)
              //console.log(tipo)
              //console.log(TClaseTipo)

              const horarioTipo = horarios.filter(horario => TClaseTipo.find(tClaseT => tClaseT.id === horario.idclase))
              //console.log(horarioTipo)

              const horarioTipoData = data1.map(dia => {
                return {
                  name: dia.name,
                  events: horarioTipo.filter( hora => hora.dia === dia.name).map(horario => {
                    //separar el string de horario.hora por cada ":"
                    const horaInicio = horario.hora.split(":")
                    const horaFin = horario.horaFin ? horario.horaFin.split(":") : horario.hora.split(":")
                    //convertir el string a numero
                    horaInicio[0] = Number(horaInicio[0])
                    horaInicio[1] = Number(horaInicio[1])
                    //console.log(horaInicio)
                    horaFin[0] = Number(horaFin[0])
                    horaFin[1] = Number(horaFin[1])

                    //console.log(`la hora fin es : ${horaFin}`)
                    
                    return {
                      startTime: horaInicio[0] + horaInicio[1] / 60,
                      endTime: horaFin[0] + horaFin[1] / 60,
                      title: horario.clase,
                      //description: horario.descripcion,
                      color: horario.status === 1 ? "royalblue" : "red",
                      data: horario
                    }
                  })
                }
              })

              return (
                <Tab key={tipo.id} eventKey={tipo.id} title={tipo.tipo} style={{maxWidth: "100vw", overflow:"auto"}}>
                  
 
                    <ScheduleView daySchedules={horarioTipoData} viewStartTime={6} viewEndTime={24} theme={customTheme} handleEventClick={(data) => {customEvent(data)}}/>
                  
                </Tab>
              )
            })}
          </Tabs>
          
          <Modal show={modalEvent.show} onHide={() => setModalEvent({...modalEvent, show: false})}>
            <Modal.Header closeButton>
              <Modal.Title>Clase: {modalEvent.clase}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Dia: {modalEvent.dia}</p>
              <p>Horario: {modalEvent.horaInicio} - {modalEvent.horaFin}</p>
              <p>Estado: {modalEvent.status === 1 ? "Activo" : "Inactivo"}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModalEvent({...modalEvent, show: false})}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>


        </div>
      </div>
    </>
  )
}