import React from "react";



import rbg1 from "../assets/Parque1.jpg"

import Header from "../Components/Header/headerV2";
import { Card4 } from "./card1";




import Novedades from "./novedades/carrousel";
import { CardAlumnoDestacado } from "./cardAlumnoDestacado";

export default function landing1(){

    return(
        <>
            <div style={{ 
                backgroundImage: `url("${rbg1}")`,
                height:'100vh',
                //marginTop:'-70px',
                //fontSize:'50px',
                backgroundSize: 'cover',
                //backgroundRepeat: 'no-repeat',
                backgroundPosition: "top"
            }}>
                <Header/>
                <div style={{paddingLeft:"5%", paddingTop:"3%"}}>
                    <div style={{position:"absolute", zIndex:2, top:"6.25%", paddingLeft:"10%"}}>
                        <h1 className="SoloTitulos" style={{ textAlign:"left", fontSize:"calc(1em + 10vw)", color:"white", textTransform:"uppercase"}}># Vive la</h1>
                        <h1 className="SoloTitulos" weight="bold" style={{ textAlign:"left", fontSize:"calc(1em + 9.5vw)", color:"#46c3c7", textTransform:"uppercase", lineHeight:0.5}}>Experiencia</h1>
                    </div>
                    <CardAlumnoDestacado/>
                </div>
                
            </div>

            <div style={{
                backgroundColor:"#242424"
            }}>
                <p>‎</p>
                
                
                <div style={{textAlign:"right", marginRight:"5%"}}>
                    <span className="SoloTitulos" span size={"calc(1vw + 3vh + 2vmin)"}  style={{lineHeight:"xs",color:"#46c3c7", textAlign:"right"}}>
                    #Novedades 
                    </span>
                    <span className="SoloTitulos"  size={"calc(1vw + 3vh + 2vmin)"}  style={{lineHeight:"xs",color:"white"}}>
                    ‎ de la Semana
                    </span>
                </div>
                
                <div style={{textAlign:"right"}}>
                    <h5 className="SubTextos" color="white" weight="medium" size={20} style={{color:"white", marginRight:"5vw"}}  >
                        Recuerda estar atento a cambios de horario, cierres o reprogramación de clases.
                    </h5>
                    
                </div>
                
                <div className="container" style={{maxHeight:"676px", marginTop:"40px", paddingBottom:"60px"}}>
                <Novedades/>
                </div>
                    
                
            </div>
        </>
    )
}