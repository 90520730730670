import { useEffect, useState } from "react"
import axios from "axios"
import { AXIOS_HOST } from "../../config/axios.config"

import { Card, Button } from "react-bootstrap"


import { Swiper, SwiperSlide } from "swiper/react";

import { EffectCoverflow, Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

function SwiperV2() {

    const [profesores, setProfesores] = useState([])

    const loadProfesores = async () => {
        const todos = await axios.get(`${AXIOS_HOST}/profes/allpublic`)
        setProfesores(todos.data.profesores)
        //console.log("Profesores cargados:", todos.data.profesores)
    }

    useEffect(() => {
        loadProfesores()
    }, [])


    return (
        <Swiper

            breakpoints={
                {
                    360: {
                        slidesPerView: 1
                    },
                    1100: {
                        slidesPerView: 3,

                    }
                }
            }


            autoplay={{
                delay: 2500,
                disableOnInteraction: true,
            }}

            effect="coverflow"
            grabCursor={true}
            centeredSlides={false}
            loop={true}

            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
            }}


            initialSlide={2}

            navigation={true}
            modules={[EffectCoverflow, Navigation]}
            className="mySwiper"

            style={{ marginBottom: "3rem", marginTop: "3rem", maxWidth: "80vw", overflow:"visible"}}
        >

            {profesores.map((profesor, index) => {
                return (
                    <SwiperSlide key={profesor.nombre + index} style={{ textAlign: "center", maxWidth: "auto" , marginTop:"auto"}}>
                        <Card style={{ maxWidth: "400px", aspectRatio: 2 / 3, marginLeft: "auto", marginRight: "auto" }}>


                            <Card.Img src={`${profesor.avatarIMG}`} style={{ objectFit: "cover", width: "100%", height: "100%" }}>

                            </Card.Img>
                            <Card.ImgOverlay style={{ display: "flex", flexDirection: "column", padding: 0, textAlign: "left" }}>


                                <div className='MyGlassmorphism' style={{ marginTop: "auto", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                                    <Card.Title style={{ paddingLeft: "10px", paddingTop: "5px", color: "white", display: "inline" }}>{profesor.nombre} {profesor.apellido}</Card.Title>
                                    <Card.Text className='multiline-ellipsis' style={{ paddingLeft: "5px", color: "white" }}>{profesor.descrip}</Card.Text>

                                    <Card.Footer style={{ padding: "5px", backgroundColor: "transparent", textAlign: "right" }}>
                                        <Button variant="outline-light" style={{ borderRadius: "20px", padding: "5px 10px", display: "inline-block" }} href="/clases/profesores">Ver más</Button>

                                    </Card.Footer>
                                </div>
                            </Card.ImgOverlay>
                        </Card>
                    </SwiperSlide>
                )
            })

            }




        </Swiper>
    )
}

export default SwiperV2