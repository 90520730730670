import React, { useRef, useState } from "react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";



// import required modules


import SwiperV2 from "./SwiperV2";


export default function App() {
    
  return (
    <div style={{backgroundColor:"#100f10", minHeight:"90vh"}}>
    <h1 style={{fontWeight:"bold", color:"azure", paddingTop:"1rem"}} >Nuestro Equipo</h1>
    
        <SwiperV2/>
        {/*<SwiperProfesoresAx/>  */}
    </div>
  );
}