import Row  from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from 'react-bootstrap/Nav'

import {BsFacebook , BsInstagram, BsWhatsapp} from "react-icons/bs"

import fondo from "../assets/footer_placeholder2.jpg"
import fondo2 from "../assets/Parque2.jpg"
import logo from "../logos/Logo1.png"


export default function FooterV2() {

    return(
        <div className="" 
            style={{
                backgroundImage:`url("${fondo2}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

                backgroundPosition: "center",
                marginTop:"auto"
                
                }}>
                    <div 
                    style={{
                        height:"100%",
                        width:"100%",
                        backgroundColor:"rgba(36,36,36,0.93 )", 
                        color:"white",
                        padding:"20px"

                    }}>
                        <div className="container">
                        
                            <Row>
                                <Col md={4}>
                                <a href="/"><img src={logo} alt="logo" style={{maxHeight:"70px", width:"auto"}}></img></a>
                                <p class="text-muted">© 2024 Parque Austral</p></Col>
                                <Col md={4}></Col>
                                <Col md={4} >
                                    <Row>
                                        <Nav className="justify-content-end ">
                                            <Nav.Item>
                                                <Nav.Link className="text-muted" href="/">Home</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className="text-muted" href="/nosotros">About</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className="text-muted" href="/dashboard">Ingreso Funcionarios</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Row>
                                    <Row >
                                        <Nav className="justify-content-end">
                                            <Nav.Item>
                                                <Nav.Link 
                                                    href="https://wa.me/+56964733320" 
                                                    target="_blank" 
                                                    rel="noreferrer">
                                                        <BsWhatsapp className='IconsWhatsApp' color="white" size={"35px"}/>
                                                </Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link 
                                                    href="https://www.facebook.com/Complejo-Deportivo-Parque-Austral-106971030984576" 
                                                    target="_blank" 
                                                    rel="noreferrer"
                                                    >
                                                    <BsFacebook className='IconsFacebook' color="white" size={"35px"}/>
                                                </Nav.Link>
                                            </Nav.Item> 

                                            <Nav.Item>
                                                <Nav.Link 
                                                    href="https://www.instagram.com/complejodeportivoparqueaustral/?hl=es" 
                                                    target="_blank" 
                                                    rel="noreferrer"
                                                    >
                                                    <BsInstagram className='IconsInstagram' color="white" size={"35px"}/>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
            
        </div>
    )
}