import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import {BsFacebook , BsInstagram, BsWhatsapp} from "react-icons/bs"
import { useState, useEffect } from 'react'

import LOGO from "../../logos/Logo1.png"
import "./styleHeader.css"

function HeaderV2() {

    const [ruta, setRuta] = useState("")

    
    useEffect(() => {
        setRuta(window.location.pathname.toLowerCase())
      }, []);

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);


    return (
        <Navbar className='PersonalizedHeader' variant="dark" expand="lg" style={{zIndex:24}}>
            
          <Container>
          <Navbar.Brand href="/">
            <img
              src={LOGO}
              width={200}
                height={"auto"}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse  id="basic-navbar-nav">
              <Nav className="ms-auto me-auto">
                <Nav.Link active={ruta.length === 1} href="/">Home</Nav.Link>
                <Nav.Link active={ruta.includes("noticias")}  href="/noticias">Noticias</Nav.Link>
                <NavDropdown active={ruta.includes("clases")}  className='PersoDropdown' menuVariant="dark"  title="Clases" id="basic-nav-dropdown">
                  <NavDropdown.Item  href="/clases/profesores">Profesores</NavDropdown.Item>
                  <NavDropdown.Item href="/clases/horarios">
                    Horarios
                  </NavDropdown.Item>
                  {/*<NavDropdown.Item href="#action/3.3">Programas</NavDropdown.Item>*/}
                </NavDropdown>
                <NavDropdown active={ruta.includes("beneficios")} menuVariant='dark' title="Beneficios" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/beneficios/beneficios">Descuentos</NavDropdown.Item>
                    <NavDropdown.Item href="/beneficios/convenios">Convenios</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href='https://parqueaustral.citamedica.cl' target="_blank" rel="noreferrer">Salud</Nav.Link>
                <Nav.Link active={ruta.includes("nosotros")} href="/nosotros">Nosotros</Nav.Link>
                <Nav.Link active={ruta.includes("ayuda")} href="/ayuda">Ayuda</Nav.Link>
              </Nav>



              <Nav>
              <Stack direction="horizontal" gap={1} style={{marginLeft:"auto", marginRight:"auto"}}>
                <Nav.Link href="https://wa.me/+56964733320" target="_blank" rel="noreferrer"><BsWhatsapp className='IconsWhatsApp' color='white' size={"25px"}/></Nav.Link>
                <Nav.Link href="https://www.instagram.com/complejodeportivoparqueaustral/?hl=es" target="_blank" rel="noreferrer" ><BsInstagram className='IconsInstagram' color='white' size={"25px"}/></Nav.Link>
                <Nav.Link href="https://www.facebook.com/Complejo-Deportivo-Parque-Austral-106971030984576" target="_blank" rel="noreferrer"><BsFacebook className='IconsFacebook' color='white' size={"25px"}></BsFacebook></Nav.Link>
            </Stack>
              <Nav.Link href="https://parqueaustral.pages.dev" target="_blank">Ingresar | Login</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
}

export default HeaderV2