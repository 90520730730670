import { useState, useEffect } from "react"
import axios from "axios"
import { AXIOS_HOST } from "../../config/axios.config"
import HeaderV2 from "../Header/headerV2"
import { Carousel } from "react-bootstrap"


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";

import { Zoom ,Navigation } from "swiper";
import { useParams } from "react-router-dom";
import RoutesWithNotFound from "../../utilities/notFoundRoutes.utility"




function NoticiaSpecific() {

  const [noticia, setNoticia] = useState({})
  const { id } = useParams();

  //obtener el parametro id de la url que esta definido como /noticia/:id





  useEffect(() => {
    
    console.log(id)
    axios.get(`${AXIOS_HOST}/noticias/${id}`).then((res) => {
      console.warn(res.data[0])
      setNoticia(res.data[0])
    })
  }, [])

  return (
    <div className="MyBackground">
      <HeaderV2 />

    {noticia !== undefined ?
      <div className="container" style={{backgroundColor:"aliceblue", minHeight:"91vh", textAlign:"center", paddingTop:"2rem"}}>
      <Swiper
        loop={true}


        navigation={true}
        zoom={true}
        modules={[Zoom,Navigation]}
        className="mySwiper"
        style={{maxWidth:"900px"}}
      >
        {noticia.archivos && noticia.archivos.map((img) => {
          return (
            <SwiperSlide>
              {img.nombreFile.includes(".jpg") || img.nombreFile.includes(".png") || img.nombreFile.includes(".jpeg") ?
              
              <img
                className="d-block w-100 swiper-zoom-container"
                src={`${AXIOS_HOST}/filesnoticias/${img.nombreFile}`} 
                alt="IMG"
                crossOrigin="anonymous"
                style={{maxHeight:"450px", aspectRatio:"2/1",}}
              />
              
              :
              <video crossOrigin="anonymous" className="d-block w-100" controls style={{maxHeight:"450px", aspectRatio:"2/1",}} muted>
                <source  src={`${AXIOS_HOST}/filesnoticias/${img.nombreFile}`} type="video/mp4" />
              </video>
            }
            </SwiperSlide>
          )
        })}
      </Swiper>



        <div className="container mt-3" style={{maxWidth:"900px"}} >
          <div className="">
            <h1>{noticia.titulo}</h1>
            <p style={{textAlign:"left",overflowWrap: 'break-word', whiteSpace: "pre-wrap"}}>{noticia.texto}</p>
            {noticia.url && <a href={noticia.url} target="_blank" rel="noreferrer">Acceda Aquí</a>
            }
          </div>
        </div>
      </div>:
      <RoutesWithNotFound/>
    }


      
    </div>
  )
}

export default NoticiaSpecific